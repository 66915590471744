<template>
  <div :class="['tw-flex', containerClass]">
    <img
      v-if="imageUrl"
      :class="[defaultImageClass, imageClass]"
      :src="imageUrl"
    >
    <div class="tw-overflow-hidden">
      <h2 :class="titleClass">
        <slot
          v-if="hasTitleSlot"
          name="title"
        />
      </h2>
      <h3 :class="subtitleClass">
        <slot
          v-if="hasSubtitleSlot"
          name="subtitle"
        />
      </h3>
      <h4 :class="detailsClass">
        <slot
          v-if="hasDetailsSlot"
          name="details"
        />
      </h4>
    </div>
  </div>
</template>

<script>
/**
 * This component displays the common layout and styles seen in the snow location header, daily
 * snow header, season pass header, and edit favorites cards. It displays an image, with a title,
 * subtitle, and optional details next to the image. It can be rendered in three sizes: small,
 * medium, and large.
 */
export default {
  name: 'ResourceHeader',

  props: {
    containerClass: {
      type: String,
      default: '',
    },
    detailsClass: {
      type: String,
      default: 'tw-mt-0.5 lg:tw-mt-1 tw-text-sm tw-whitespace-nowrap',
    },
    imageClass: {
      type: String,
      default: '',
    },
    imageSize: {
      type: String,
      default: null,
      validator(value) {
        if (!value) {
          return true;
        }

        return ['xs', 'sm', 'base', 'lg', 'xl'].includes(value);
      },
    },
    imageUrl: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'small',
      validator(value) {
        return ['large', 'medium-large', 'medium', 'small'].includes(value);
      },
    },
  },

  computed: {
    hasDetailsSlot() {
      return !!this.$slots.details;
    },

    hasSubtitleSlot() {
      return !!this.$slots.subtitle;
    },

    hasTitleSlot() {
      return !!this.$slots.title;
    },

    isLarge() {
      return this.size === 'large';
    },

    isMedium() {
      return this.size === 'medium';
    },

    defaultImageClass() {
      const xl = 'tw-hidden lg:tw-block tw-w-24 tw-h-24 tw-mr-2.5 tw-rounded-lg';
      const lg = 'tw-block tw-w-20 tw-h-20 tw-mr-2.5 tw-rounded-lg';
      const base = 'tw-block tw-w-16 tw-h-16 tw-mr-2.5 tw-rounded-md';
      const sm = 'tw-block tw-w-12 tw-h-12 tw-mr-2.5 tw-rounded-md';
      const xs = 'tw-block tw-w-10 tw-h-10 tw-mr-2.5 tw-rounded';

      if (this.imageSize === 'xl') {
        return xl;
      }

      if (this.imageSize === 'lg') {
        return lg;
      }

      if (this.imageSize === 'base') {
        return base;
      }

      if (this.imageSize === 'sm') {
        return sm;
      }

      if (this.imageSize === 'xs') {
        return xs;
      }

      if (this.isLarge) {
        return xl;
      }

      if (this.isMedium) {
        return sm;
      }

      return xs;
    },

    subtitleClass() {
      if (this.isLarge) {
        return 'tw-text-lg';
      }

      if (this.isMedium) {
        return 'tw-text-base tw-font-medium';
      }

      return 'tw--mt-0.5 tw-text-sm tw-font-medium';
    },

    titleClass() {
      if (this.isLarge) {
        return 'page-title heading-color';
      }

      if (this.isMedium) {
        return 'md:tw-mb-1 tw-text-xl tw-font-semibold tw-leading-none';
      }

      return 'tw-mb-0.5 tw-text-base tw-leading-snug tw-font-semibold';
    },
  },
};
</script>
